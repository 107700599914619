import { useState } from 'react';
import { IconHeart } from '@krakentech/icons';
import { getCookie, setCookie } from 'cookies-next';

import EnrichmentCard from '@/components/EnrichmentCard';
import { usePsrRenewalDue } from '@/hooks/accounts/usePsrRenewalDue';
import { COOKIES } from '@/utils/cookies';
import { INTERNAL_PATHS } from '@/utils/urls';

const PSREnrichmentCard = () => {
  const [displayComponent, setDisplayComponent] = useState(true);

  const { data, isLoading, isError } = usePsrRenewalDue();

  const PSRHref = `${INTERNAL_PATHS.PRIORITY_SERVICES_REGISTER.path}?viaDashboard=true`;

  if (!data || isLoading || isError) return null;

  const { SNOOZE_PSR_UPDATE } = COOKIES;

  const handleClose = () => {
    setCookie(SNOOZE_PSR_UPDATE, true);
    setDisplayComponent(false);
  };

  if (!displayComponent || getCookie(SNOOZE_PSR_UPDATE)) return null;

  return (
    <EnrichmentCard
      title="Let's update your Priority Services Register"
      subtitle="Let us know about any extra help or support you might need"
      icon={<IconHeart size={32} />}
      buttonActionTitle="Update"
      closeCard={handleClose}
      href={PSRHref}
    />
  );
};

export default PSREnrichmentCard;
